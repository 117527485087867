<template>
    <PageWrapper v-if="assetData">
        <NebulaGridRow type="flex" class="asset__breadcrumbs-row">
            <NebulaColumn :columns="12">
                <NebulaBreadcrumbs
                    :aria-label="$t('breadcrumbs-aria', { ns: 'usermanagement' })"
                    :breadcrumbs="breadcrumbs"
                    dropdownUseRouterLinks
                    showDimText
                />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <div v-if="type === 'text'" class="asset__pdf-wrapper">
                    <a :href="mediaFile">
                        <img class="asset__pdf-preview" :src="thumbnail">
                    </a>
                    <NebulaButton :link="mediaFile" text="Download" target="_blank" icon-left="download" size="s" />
                </div>
                <iframe
                    allowfullscreen
                    v-if="type ==='interactive'"
                    class="asset__presentation-embed"
                    :src="`${assetRoot}${mediaFile}`"
                />
                <div v-if="type === 'other material'" class="asset__pdf-wrapper">
                    <iframe
                        v-if="type ==='other material'"
                        class="asset__presentation-embed"
                        :src="mediaFile"
                    />
                    <NebulaButton :link="mediaFile" download target="_blank" text="Download" icon-left="download" size="s" />
                </div>
                <div v-if="type ==='video'" class="asset__video-wrapper">
                    <video controls class="asset__video" ref="careers-video" @click="toggleVideo">
                        <source :src="videoMediaFiles.large" type="video/mp4">
                    </video>
                    <div class="asset__video-play-button" v-if="!videoPlaying">
                        <button
                            @click="toggleVideo"
                            aria-label="play video"
                            :data-click-type="`Asset Details: Video ${assetId}`"
                            data-click-name="Video Playback Started"
                        >
                            <NebulaIcon size="xxl" symbolId="circle-play--filled" />
                        </button>
                    </div>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <p class="asset__duration" v-if="videoDuration">{{ videoDuration }}</p>
                <h2 class="asset__title">{{ assetData && assetData.title }}</h2>
                <div v-if="assetData.org && assetData.org.id" class="asset__org-row-wrapper">
                    <component
                        :is="orgLinkComponent"
                        class="asset__org-row asset__organization-link"
                        v-bind="orgLinkComponent === 'router-link' ? { to: orgLink } : { href: orgLink }"
                    >
                        <ProfileAvatar :image="assetData.org && assetData.org.avatarUrl" icon="buildings" :logo="assetData?.org?.evergreen" />
                        <h3 class="asset__organization-name">{{ assetData.org && assetData.org.name }}</h3>
                    </component>
                </div>
                <div class="asset__org-row" v-else>
                    <ProfileAvatar :image="assetData.org && assetData.org.avatarUrl" icon="buildings" :logo="assetData?.org?.evergreen" />
                    <h3 class="asset__organization-name">{{ assetData.org && assetData.org.name }}</h3>
                </div>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex" class="asset__description-row">
            <NebulaColumn :columns="12">
                <h3 class="asset__content-header">Content Description</h3>
                <p>{{ assetData.description }}</p>
            </NebulaColumn>
        </NebulaGridRow>
        <PageSection v-if="Object.keys(related).length > 0" title="Related">
            <NebulaGridRow gutter class="asset__related-resources">
                <NebulaGridBlock
                    v-for="(resource, idx) in related"
                    :key="`asset-${idx}`"
                    :columns="3"
                    :mdSize="4"
                    :smSize="4"
                >
                    <ResourceCard :asset="resource" :loading="resource.loading" />
                </NebulaGridBlock>
            </NebulaGridRow>
        </PageSection>
    </PageWrapper>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import ResourceCard from '@/components/shared/cards/ResourceCard.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import breadcrumbs from '@/mixins/breadcrumbs';
import createPayload from '@/mixins/data/createPayload';
import { getAtlasPageIdFromOrgId, getRelatedAssets } from '@/utils/data/resources';
import {
    NebulaBreadcrumbs,
    NebulaButton,
    NebulaGrid,
    NebulaIcon,
} from '@discoveryedu/nebula-components';
import { RouterLink } from 'vue-router';

import { ATLAS_APP_BASE_URL, envMap } from '@/constants';

export default {
    name: 'AssetDetails',
    components: {
        NebulaButton,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaIcon,
        PageSection,
        NebulaBreadcrumbs,
        ProfileAvatar,
        ResourceCard,
        PageWrapper,
        RouterLink,
    },
    mixins: [createPayload, breadcrumbs],
    computed: {
        assetRoot() {
            let envSlug = '';
            if (Object.keys(envMap).includes(process.env.NODE_ENV)) {
                envSlug = envMap[process.env.NODE_ENV];
            }
            return `https://app.${envSlug}discoveryeducation.com`;
        },
        assetData() {
            return this.$store.state.page.assets[this.$route.params.id];
        },
        mediaFile() {
            if (!this.assetData.clean_media_files) {
                return '';
            }
            return this.assetData.clean_media_files[0].url;
        },
        thumbnail() {
            return `https://d1m0scxbrw6oep.cloudfront.net/thumbnails/${this.$route.params.id}-xlarge`;
        },
        duration() {
            return this.assetData && this.assetData.duration;
        },
        type() {
            return this.assetData && this.assetData.group;
        },
        related() {
            return this.$store.state.page.related_assets;
        },
        videoDuration() {
            const { duration } = this.assetData;

            if (!duration) {
                return null;
            }

            const minutes = Math.floor(duration / 60);
            const seconds = duration - minutes * 60;

            let string = `${minutes} minutes`;
            if (seconds > 0) {
                string = string.concat(`, ${seconds} seconds`);
            }

            return string;
        },
        videoMediaFiles() {
            if (!this.assetData.media_files) {
                return null;
            }

            let large;

            const largeRecord = this.assetData.media_files.find((obj) => obj.format.id === 35);
            if (largeRecord) {
                large = largeRecord.url;
            }
            return {
                large,
            };
        },
        orgLinkComponent() {
            const atlasPageId = getAtlasPageIdFromOrgId(this.assetData.org.id);
            return this.$store.state.app.flags['atlas-content'] && atlasPageId ? 'a' : 'router-link';
        },
        orgLink() {
            const atlasPageId = getAtlasPageIdFromOrgId(this.assetData.org.id);
            return this.$store.state.app.flags['atlas-content'] && atlasPageId
                ? `${ATLAS_APP_BASE_URL}/pages/${atlasPageId}`
                : `/organization/${this.assetData.org.id}`;
        },
    },
    data() {
        return {
            organizationContent: null,
            resources: [],
            associatedOrg: null,
            evergreen: false,
            videoPlaying: false,
            assetId: this.$route.params.id,
        };
    },
    async mounted() {
        const assetId = this.$route.params.id;

        // eslint-disable-next-line global-require
        this.organizationContent = require('../data/assets/org_content.json');

        const related = getRelatedAssets(assetId);

        const payload = await this.createPayload({ assetId });
        await this.$store.dispatch('getResource', payload);

        if (!this.assetData) {
            this.$router.push('/');
        }

        if (this.assetData.type.name.toLowerCase().trim() === 'pdf activity' && !this.mediaFile) {
            this.$router.push('/');
        }

        await Promise.all(
            related.map(async (id) => {
                const relatedPayload = await this.createPayload({ assetId: id, type: 'related', max: 4 });
                return this.$store.dispatch('getResource', relatedPayload);
            }),
        );

        if (this.type === 'video') {
            this.$refs['careers-video'].addEventListener('play', () => {
                this.videoPlaying = true;
            });
            this.$refs['careers-video'].addEventListener('pause', () => {
                this.videoPlaying = false;
            });
        }
    },
    methods: {
        toggleVideo(e) {
            e.preventDefault();
            if (this.type === 'video') {
                if (this.videoPlaying) {
                    this.$refs['careers-video'].pause();
                } else {
                    this.$refs['careers-video'].play();
                }
            }
        },
    },
    async beforeDestroy() {
        const { assetId } = this;

        if (this.type === 'video') {
            this.$refs['careers-video'].removeEventListener('play', () => {
                this.togglePlayButton();
            });
            this.$refs['careers-video'].removeEventListener('pause', () => {
                this.togglePlayButton();
            });
        }

        await this.$store.dispatch('removeAsset', { assetId, type: 'assets' });
    },
};
</script>

<style lang="stylus">
asset-display() {
    border: none;
    border-radius: $nebula-border-radius-small;
    height: 250px;
    margin-block-end: $nebula-space-4x;
    width: 100%;
    object-fit: contain;

    @media (min-width: $nebula-breakpoints-desktop) {
        height: 600px;
        margin-block-end: $nebula-space-5x;
    }
}

play-button() {
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    --nebula-icon-size-xxl: 80px;

    @media (min-width: $nebula-breakpoints-desktop) {
        left: calc(50% - 65px);
        top: calc(50% - 65px);
        --nebula-icon-size-xxl: 130px;
    }
}

org-name() {
    nebula-text-semibold();
    font-size: $nebula-font-size-body-2;
}

.asset {
    &__breadcrumbs-row {
        margin-block-end: $nebula-space-5x;
    }

    &__duration {
        nebula-text-semibold();
        color: $nebula-color-platform-neutral-800;
        font-size: $nebula-font-size-caption;
        margin-block-start: 0;
        margin-block-end: $nebula-space-1x + $nebula-space-half;
    }

    &__presentation-embed {
        asset-display();
        border: none;
        width: 100%;
    }

    &__pdf-preview {
        asset-display();
    }

    &__org-row {
        align-items: center;
        display: flex;
        gap: $nebula-space-2x;
        padding-block-start: $nebula-space-half;
        h3 {
            margin: 0;
        }
    }

    &__org-row-wrapper {
        display: flex;
    }

    &__description-row {
        margin-block: $nebula-space-5x;
    }

    &__organization-name {
        org-name();
    }

    &__organization-link {
        text-decoration: none;
        .asset__organization-name {
            org-name();
            link();
        }
    }

    &__title {
        font-size: $nebula-font-size-card-title;
        margin: 0;
        margin-block-end: $nebula-space-1x;
    }

    &__content-header {
        margin: 0;
    }

    &__pdf-wrapper {
        margin-bottom: $nebula-space-3x;
    }

    &__video-play-button {
        play-button();
        border: none;
        background: transparent;
        position: absolute;

        button {
            border: none;
            background: transparent;
            cursor: pointer;
            svg {
                fill: var(--nebula-button-background);
                transition: var(--nebula-button-transition);
            }
            &:hover {
                svg {
                    fill: var(--nebula-button-background-hover);
                }
            }
        }
    }

    &__video {
        object-fit: contain;
        width: 100%;
        height: 100%;
        &[poster] {
            object-fit: cover;
        }
    }

    &__video-wrapper {
        asset-display();
        position: relative;
        background-color: $nebula-color-black;

        @media (min-width: $nebula-breakpoints-desktop) {
            height: 600px;
        }
    }
}
</style>
